<template>
  <address-com position="Company"></address-com>
</template>

<script>
import addressCom from "./address.vue";
export default {
  components: {
    addressCom,
  },
  data() {
    return {
      position:'company'
    };
  },
};
</script>

<style lang="scss">
</style>